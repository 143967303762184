import * as React from "react"

export default function HeroSvg({ className }) {
  return (
    <svg
      className={className}
      width="100%"
      height={137}
      viewBox="0 0 1436 137"
      fill="none"
      preserveAspectRatio="none"
      // style={{ transform: "rotateY(180deg)" }}
    >
      <path
        d="M537.5 103.304C276.791 103.304-4 64.438-4 64.438V137h1440V13.259c-272.5-57.377-572.613 90.045-898.5 90.045z"
        fill="#fff"
      />
    </svg>
  )
}
